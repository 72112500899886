// app
@import 'variables/index';
@import "reset";
@import 'typography';
@import 'utils';
@import 'extends';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-default;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
  font-weight: $font-weight-medium;
  background: $color-page-bg;
  color: $color-text-regular;
}

.container {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@include desktop {
  .container {
    width: 1000px;
  }
}

@include tablet {
  .container {
    width: 800px;
  }
}

@include tablet-small {
  .container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@include mobile {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
