@import "src/assets/scss/mixins";

.layout.-default {
  header.component.-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  main {
    position: relative;
    width: 100vw;
    height: 100vh;
    min-height: 700px;
    padding-top: 140px;

    @include tablet {
      min-height: inherit;
    }
  }
}
