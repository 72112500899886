.flex,
.inline-flex {
  display: flex;
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.column {
    flex-direction: column;
  }
  &.j-start {
    justify-content: flex-start;
  }
  &.j-center {
    justify-content: center;
  }
  &.j-between {
    justify-content: space-between;
  }
  &.j-end {
    justify-content: flex-end;
  }
  &.j-around {
    justify-content: space-around;
  }
  &.a-start {
    align-items: flex-start;
  }
  &.a-center {
    align-items: center;
  }
  &.a-end {
    align-items: flex-end;
  }
  &.a-stretch {
    align-items: stretch;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.grow,
  .grow {
    flex-grow: 1;
  }
  &.shrink,
  .shrink {
    flex-shrink: 0;
  }
}

.inline-flex {
  display: inline-flex;
}

.buttons {
  display: flex;
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.relative {
  position: relative;
}

.scroll-parent {
  position: relative;
  height: 100%;
  overflow: auto;
}

[hidden] {
  display: none !important;
}
