@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component.-slider {
  position: relative;
  padding-right: 100px;
  margin-top: 3%;

  .slides {
    height: $slider-height;
  }

  .indicator {
    position: absolute;
    bottom: 40px;
    right: 15px;
    z-index: 2;
    user-select: none;

    .indexes {
      margin-right: 14px;
    }
  }

  .next-button {
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: rgba($color-primary, .3);
    transition: $transition-button;
    cursor: pointer;

    .component.-circle-ring {
      position: absolute;
      z-index: 2;
    }

    &:hover {
      background: $color-primary;
    }
  }

  &.-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.-appearing {
    animation: appear 1s ease;

    .slides {
      & > * {
        &:not(:first-child) {
          opacity: 0;
        }
      }
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes growProgressBar {
    0%, 33% { --pgPercentage: 0; }
    100% { --pgPercentage: var(--value); }
  }
}


@include tablet {
  .component.-slider {
    margin-top: 0;
    margin-right: 0;
    padding-right: 0;

    .indicator {
      display: none;
    }
  }
}
