$color-primary: #3438b1;
$color-primary-highlight: darken($color-primary, 4%);

$color-dark: #1F1F1F;
$color-light: #FBFBFB;
$color-danger: #bf1d4a;
$color-success: #85D79B;
$color-warning: #FDBF61;

$color-page-bg: $color-dark;
$color-content-bg: #f1f1f1;
$color-modal-bg: #f1f1f1;

$color-text-light: $color-light;
$color-text-dark: $color-dark;
$color-text-gray: #5E5F62;
$color-text-regular: $color-text-light;

$color-shadow: rgba(#000, 0);
$color-shadow-highlight: rgba(#000, .15);
