@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.page.-home {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-top: 140px;
  padding-bottom: 100px;
  overflow: hidden;

  .container {
    position: relative;
    z-index: 1;
  }

  .copyright-container {
    justify-content: flex-end;
  }

  h1 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: $font-weight-thin;
    margin-bottom: 30px;
  }

  .copyright {
    position: absolute;
    bottom: 40px;
    z-index: 1;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/svg/bg.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom left;
  }

  .stars-bg {
    position: absolute;
    top: -20%;
    left: 0;
    width: 971px;
    height: 100%;
    background-image: url('../../assets/svg/bg-stars.svg');
    background-size: contain;
    background-repeat: no-repeat;
    opacity: .95;
  }
}

@include tablet {
  .page.-home {
    height: auto;
    min-height: 100vh;

    .copyright-container {
      justify-content: center;
    }

    .stars-bg {
      width: 70%;
      top: -12%;
    }

    .bg {
      background-position: top left;
      background-size: 80% auto;
    }
  }
}

@include tablet-small {
  .page.-home {
    .bg {
      background-image: url('../../assets/svg/bg-long.svg');
      background-size: 85% auto;
    }
  }
}

@include mobile {
  .page.-home {
    padding-top: 90px;

    h1 {
      font-size: 14px;
    }

    .stars-bg {
      width: 80%;
      top: -6%;
    }
  }
}
