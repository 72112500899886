@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component.-slide {
  .info {
    width: 450px;
    margin-right: 70px;
  }

  .image {
    position: relative;
    width: 500px;
    height: $slider-height;
    background: $color-dark;
    border-radius: $border-radius-extra-large;
    overflow: hidden;
    box-shadow: 10px 10px 30px 0 rgba(#000, .2);
    user-select: none;
    transition: all 2s ease;

    video {
      display: block;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
    }

    & > a {
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $border-radius-extra-large;
      }
    }

    &:before {
      content: '';
    }
  }

  .title {
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 24px;
  }

  .actions {
    margin-top: 40px;
  }

  &.-active {
    z-index: 1;
  }
}

.swiper {
  overflow: visible !important;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.swiper-slide-active {
  //z-index: 1;
  animation: index-in 1s forwards;

  .info {
    opacity: 0;
    animation: fade-in .5s forwards .5s ease;
  }

  .image {
    animation: rotate-in 1s forwards;
    transform-origin: bottom right;
  }
}

.swiper-slide:not(.swiper-slide-active) {
  opacity: 1 !important;
  //z-index: 0;
  animation: index-out 1s forwards;

  .info {
    opacity: 0;
    visibility: hidden;
    animation: fade-out .5s forwards ease;
  }

  .image {
    animation: rotate-out 1s forwards;
    transform: translate(35%, -25%) scale(.8);

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-dark, .8);
      transition: all 1s ease;
    }
  }
}

@keyframes index-in {
  from {
    z-index: 0;
  }
  60% {
    z-index: 1;
  }
  to {
    z-index: 1;
  }
}

@keyframes index-out {
  from {
    z-index: 1;
  }
  60% {
    z-index: 0;
  }
  to {
    z-index: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(-10%, 0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(10%, 0);
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate-in {
  from {
    transform: rotate(0deg) translate(35%, -25%) scale(0.8);
    z-index: 0;
  }
  50% {
    transform: rotate(40deg) translate(35%, -25%) scale(0.8);
    z-index: 0;
  }
  51% {
    transform: rotate(40deg) translate(35%, -25%) scale(0.8);
    z-index: 2;
  }
  to {
    transform: rotate(0deg);
    z-index: 2;
  }
}

@keyframes rotate-out {
  from {
    transform: rotate(0deg);
    z-index: 2;
    transform-origin: top left;
  }
  50% {
    transform: rotate(40deg);
    z-index: 2;
    transform-origin: top left;
  }
  51% {
    transform: rotate(40deg);
    z-index: 0;
    transform-origin: top left;
  }
  to {
    transform: rotate(0deg) translate(35%, -25%) scale(0.8);
    z-index: 0;
    transform-origin: center;
  }
}

@include desktop {
  .component.-slide {
    .info {
      margin-right: 30px;
    }

    .title {
      font-size: 38px;
      line-height: 42px;
    }

    .image {
      width: 400px;
      height: 320px;
    }
  }
}

@include tablet {
  .component.-slide {
    flex-wrap: wrap;
    justify-content: center;

    .info {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: justify;
      width: 100%;
      margin-right: 0;
      margin-bottom: 80px;
    }

    .title,
    .content {
      width: 420px;
      text-align: center;
    }
  }
}

@include mobile {
  .component.-slide {
    width: 100%;

    .title {
      width: 100%;
      font-size: 28px;
      line-height: 36px;
    }

    .content {
      width: 100%;
      font-size: 15px;

      br {
        display: none;
      }
    }

    .actions {
      margin-top: 30px;
    }
  }
}
