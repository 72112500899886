@mixin font-face ($name, $style, $weight, $path, $svg-id) {
  @font-face {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    src: url("#{$path}.woff2") format("woff2"),
    url("#{$path}.woff") format("woff"),
    url("#{$path}.svg##{$svg-id}") format("svg");
  }
}

@mixin wide {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin tablet-small {
  @media (max-width: 839px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 539px) {
    @content;
  }
}
