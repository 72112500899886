@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component.-header {
  width: 100%;
  padding: 34px 0 30px;

  .logo {
    margin-top: -14px;

    a {
      display: block;
    }
  }

  nav {
    flex-grow: 1;
    margin: 0 60px;
    font-size: 18px;
    line-height: 21px;
    font-weight: $font-weight-semibold;

    & > * {
      display: inline-flex;
      padding: 20px;
      margin: 0 10px;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .actions {
    justify-self: flex-end;
  }

  a {
    color: $color-text-regular;
  }
}

@include tablet {
  .component.-header {
    .logo {
      width: 120px;
    }

    nav {
      margin: 0 30px;
      font-size: 16px;

      & > * {
        margin: 0 8px;
      }
    }
  }
}

@include tablet-small {
  .component.-header {
    & > .container {
      justify-content: center;
    }
    nav {
      display: none;
    }
  }
}

@include mobile {
  .component.-header {
    .logo {
      width: 84px;
    }

    nav {
      margin: 0 0 0 20px;
      font-size: 14px;

      & > * {
        margin: 0 5px;
        padding: 10px;
      }
    }
  }
}
