@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component.-copyright {
  font-size: 13px;
  line-height: 15px;
  color: $color-text-gray;

  a {
    color: $color-text-gray;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include mobile {
  .component.-copyright {
    font-size: 10px;
    font-weight: $font-weight-semibold;
  }
}
