* {
  vertical-align: baseline;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  // border: none;
  padding: 0;
  margin: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
  outline: none;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
}

button {
  font-family: initial;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

a img, :link img, :visited img {
  border: 0;
}
