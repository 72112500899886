@import "colors";

$border-radius-small: 5px;
$border-radius-default: 8px;
$border-radius-large: 10px;
$border-radius-extra-large: 40px;

$box-shadow-default: 0px 7px 10px 0px $color-shadow;
$box-shadow-default-highlight: 0px 7px 12px 0px $color-shadow-highlight;

$slider-height: 400px;
