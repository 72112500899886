@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.button.-component {
  & > * {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border: none;

    font-family: $font-family-default;
    font-weight: $font-weight-semibold;
    letter-spacing: .2px;
    text-decoration: none !important;

    background: $color-primary;
    color: $color-text-light;
    cursor: pointer;
    transition: $transition-button;

    span {
      display: flex;
    }

    .icon-container {
      color: $color-text-light;

      & + * {
        margin-left: 8px;
      }
    }

    &:hover {
      background: $color-primary-highlight;
    }

    &:focus {
      background: $color-primary-highlight;
    }
  }

  .loader {
    color: $color-text-light;
  }

  &.-theme {
    &-default {
      .icon-container {
        color: $color-text-regular;
      }
    }

    &-hovered {
      & > * {
        background: $color-primary-highlight;

        &:hover {
          background: darken($color-primary-highlight, 2%);
        }
      }
    }

    &-danger {
      & > * {
        background: $color-danger;
        color: $color-text-light;

        &:hover {
          background: darken($color-danger, 5%);
        }
      }
    }

    &-white {
      & > * {
        background: $color-light;
        color: $color-primary;

        &:hover {
          background: lighten($color-primary, 42%);
        }
      }
    }

    &-ghost {
      & > * {
        background: transparent;
        color: $color-text-light;

        &:hover {
          background: rgba($color-light, .02);
        }
      }
    }
  }

  &.-size {
    &-default {
      & > * {
        padding: 18px 30px 20px;
        min-height: 48px;
        font-size: 18px;
        line-height: 21px;
        border-radius: 30px;
      }
    }

    &-small {
      & > * {
        padding: 8px 18px 9px;
        min-height: 40px;
        font-size: 13px;
        line-height: 16px;
        border-radius: $border-radius-small;

        .icon-container {
          width: 20px;
          height: 20px;
          margin-left: -2px;

          & + * {
            margin-left: 8px;
          }
        }
      }
    }
  }

  &.-full-width {
    & > * {
      width: 100%;
    }
  }

  &.-loading {
    & > * {
      position: relative;
      pointer-events: none;

      & > * {
        &:not(.loader) {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  &.-disabled {
    & > * {
      pointer-events: none;
      opacity: .5;
    }
  }
}

@include tablet {
  .button.-component {
    &.-size-default {
      & > * {
        padding: 18px 24px 20px;
      }
    }
  }
}

@include mobile {
  .button.-component {
    &.-size-default {
      & > * {
        font-size: 16px;
        padding: 16px 22px 18px;
      }
    }
  }
}
